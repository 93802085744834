.payroll-print-fvp {
  width: 1024px;
  margin: 0 auto;
  background-color: #f4f8fc;
  text-align: initial;
  min-height: 100vh;
 }

 .payroll-print-fvp .title {
  font-weight: bold;
  font-size: 12pt;
  margin: 10px 0;
 }
